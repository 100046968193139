
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';



export const productService = {
    getAllProducts,
    saveNewProduct,
    getProductMasterData,
    submitProductPlan,
    getProductByPlan,
    getProductAddonMasterData,
    submitProductAddon,
    getProductAddon,
    getSingleAddon,
    getCouponMasterData,
    submitProductCoupon,
    getProductCoupon,
    getCouponDetails,
    submitPlanImage,
    getPlanImageList,
    uploadProductImage,
    getProductByCategory,
    changeProductInStock,
    changeProductStatus,
    submitProductPLanImage,
    updateProductStatus
    
};




async function  getAllProducts() {
   return axios.get(`/products/get-all-products`,  authHeader());
}


async function  uploadProductImage(imageData) {
   return axios.post(`/external-grocedy/product-image-uploader`, imageData, authHeader());
}



async function  saveNewProduct(data) {
   return axios.post(`/products/save-new-product`,data,  authHeader());
}



async function  getProductMasterData(id) {
   return axios.get(`/products/get-product-master-data/${id}`,  authHeader());
}


async function  getCouponMasterData(id) {
   return axios.get(`/products/get-coupon-master-data/${id}`,  authHeader());
}


async function  changeProductStatus(formData) {
   return axios.patch(`/products/change-product-status`, formData ,  authHeader());
}

async function  getSingleAddon(addon_id, product_id) {
   return axios.get(`/products/get-single-addon/${addon_id}/${product_id}`,  authHeader());
}



async function  getProductByPlan(id) {
   return axios.get(`/products/get-product-by-plan/${id}`,  authHeader());
}

async function  getProductAddon(id) {
   return axios.get(`/products/get-product-addon/${id}`,  authHeader());
}


async function  getProductCoupon(id) {
   return axios.get(`/products/get-product-coupon/${id}`,  authHeader())
}


async function  getCouponDetails(coupon_id,product_id) {

   return axios.get(`/products/get-coupon-details/${coupon_id}/${product_id}`,  authHeader())
}



async function  getProductAddonMasterData() {
   return axios.get(`/products/get-product-addon-master-data`,  authHeader())
}


async function  submitProductPlan(data) {
   return axios.post(`/products/submit-product-plan`,data,  authHeader())
}

async function  updateProductStatus(data) {
   return axios.put(`/products/update-product-status`,data,  authHeader())
}

async function  submitProductCoupon(data) {
   return axios.post(`/products/submit-product-coupon`,data,  authHeader())
}

async function  submitProductAddon(data) {
   return axios.post(`/products/submit-product-addon`,data,  authHeader())
}

async function  submitPlanImage(data) {
   return axios.post(`/products/submit-plan-image`,data,  authHeader())
}

async function  submitProductPLanImage(data) {
   return axios.post(`/products/submit-product-image`,data,  authHeader())
}

async function  getPlanImageList(plan_id, product_id) {
   return axios.get(`/products/get-product-plan-images/${plan_id}/${product_id}`,  authHeader());
}


async function  getProductByCategory(option) {
   return axios.get(`/products/get-product-product-by-category/${option}`,  authHeader());
}

async function  changeProductInStock(data) {
   return axios.post(`/products/change-product-in-stock`,data, authHeader());
}



// async function  getItemStockInformation(id) {
//    return axios.get(`/item-stock-information/${id}`,  authHeader())
// }


// function updateItemTableHeader(xmlString){
//    var body = {xml_string : xmlString}
//    return axios.put('/update-item-table-header', body, authHeader())
        
//  }








